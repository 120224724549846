.rentroll_table {
  thead {
    th {
      background-color: #fff !important;
    }
    tr > td {
      background-color: #fff;
    }
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
  tr.ant-table-row-expand-icon-cell {
    background-color: #fff;
  }
}
