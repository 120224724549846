.rentroll_table {
  thead {
    th {
      background-color: #fff !important;
    }
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
}
