.table_wrapper {
  padding: 0px 20px 0px 20px;
  thead {
    th {
      background-color: #fff !important;
    }
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
}
.tableWrapper {
  thead {
    th {
      background-color: #275aff !important;
      h4 {
        color: #fff !important;
      }
    }
  }
}
