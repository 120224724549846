.page_heading {
  // font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #89919d;
  text-transform: uppercase;
}
.community_name {
  // font-family: Avenir;
  font-size: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: normal;
  color: #222;
}
.survey_btn {
  min-width: 156px;
  height: 40px;
  margin: 29px 0px 29px 16px;
  padding: 10px 30.5px 10px 29.5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
  background-color: #275aff;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  cursor: pointer;
  // margin-right: 10px;
}
.competitor_btn {
  min-width: 168px;
  height: 40px;
  margin: 29px 30px 29px 10px;
  padding: 10px 29.5px 10px 30.5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  cursor: pointer;
}

// compare features
.table_header {
  // font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #222;
}
.table_value {
  // font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
}
.service_box {
  width: 396px;
  padding: 4px 30px 6px 20px;
  border-radius: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
}
.service_name {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #222;
}
.service_value {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #89919d;
}

// sub competitor
.sub_competitor_tab [class~="ant-tabs-nav"] {
  padding: 0px;
  margin-top: 20px;
}

.sub_competitor_tab [class~="ant-tabs-tab-active"] {
  & [class~="ant-tabs-tab-btn"] {
    color: #fa7550 !important;
  }
}
.sub_competitor_tab [class~="ant-tabs-ink-bar"] {
  background-color: #fa7550 !important;
}
.mgs__scroll {
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
}

.closeBtn {
  visibility: hidden;
  opacity: 0;
}
.item_card:hover .closeBtn {
  visibility: visible;
  opacity: 1;
}

.competitor_item_remove_btn {
  visibility: hidden;
  opacity: 0;
}
.competitor_item:hover .competitor_item_remove_btn {
  visibility: visible;
  opacity: 1;
}

// @media (max-width: 767px) {
//   text-align: center;
// }
@media (max-width: 400px) {
  .community_name {
    font-size: 35px;
    line-height: 40px;
  }
  .survey_btn {
    // font-size: 12px;
    padding-right: 0px;
    padding-left: 0px;
    margin: 0 10px 0 0;
  }
  .competitor_btn {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0;
  }
}
