.table_wrapper {
  border-spacing: 0 !important;
}
.acc_table_wrapper th {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.acc_table_wrapper td {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.acc_row {
  position: relative;
}
.btnStyle {
  box-shadow: none;
}
