.header_main {
  height: 50px;
  padding: 10px 30px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  // border-bottom: solid 1px #d7d7d7;
}
.logo_title {
  // font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
}
.header_right {
  display: flex;
  align-items: center;
}
.username_text {
  // font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #222;
}
.select_inputs [class~="ant-select-selector"] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
}
.select_inputs [class~="ant-select-single"]:hover .ant-select-selector {
  border-color: inherit !important;
}
